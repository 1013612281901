import React from "react"
import { Link } from "gatsby"

const Footer = () => {
  return (
    <footer className="footer has-background-white-ter">
      <div className="content has-text-centered has-text-black">
        <p className="has-text-weight-bold has-text-black">
          Brought to you by{" "}
          <a
            href="https://twitter.com/Mega_Mentor"
            className="tag is-medium is-primary has-text-black is-underlined"
          >
            @Mega_Mentor
          </a>
        </p>
      </div>
      <div className="section has-text-centred">
        <nav className="container">
          <ul className="level">
            <li className="level-item">
              <Link className="has-text-black is-underlined" to="/signup">
                Sign up
              </Link>
            </li>
            <li className="level-item">
              <Link className="has-text-black is-underlined" to="/privacy">
                Privacy
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </footer>
  )
}

export default Footer
