import { Link } from "gatsby"
import React from "react"
import LongLogo from "../images/longlogo@4x.png"

const NavBar = ({ siteTitle }) => {
  return (
    <nav className="navbar" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <Link to="/" className="navbar-item">
          <img src={LongLogo} alt="Mega Mentor" />
        </Link>
      </div>
    </nav>
  )
}

export default NavBar
